@font-face {
  font-family: 'din-light';
  src: url('./fonts/din_light_regular-webfont.woff2') format('woff2'),
       url('./fonts/din_light_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'din-medium';
  src: url('./fonts/din-medium-webfont.woff2') format('woff2'),
       url('./fonts/din-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@keyframes inAndOut {
  0% {transform: scale(1);}
  10% {transform: scale(1);}
  50% {transform: scale(1.1);}
  90% {transform: scale(1);}
  100% {transform: scale((1));}
}

body {
  touch-action: manipulation;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: #003c66;
  background-image: url('../img/background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  font-weight: 800;
}

h2 {
  height: 50px;
  width: 100%;
  background: #003c66;
  color: #f3bf00;
  font-family: 'din-light';
  font-size: 25px;
  display: flex;
  align-items: center;
}

h2 span {
  color: #fff;
  font-family: 'din-medium';
  display: flex;
  align-items: center;
}

h2 span::before {
  content: "";
  display: inline-block;
  width: 3px;
  height: 3px;
  background: #fff;
  margin: 0 10px;
}

h3 {
  text-transform: uppercase;
  color: #2ad7f4;
}

h3, p, li {
  font-family: 'din-medium';
  font-size: 14px;
  line-height: 1.25;
}

h4 {
  font-family: 'din-medium';
  font-size: 12px;
  color: #003c66;
}

.clickable:active {
  transform: scale(0.95);
  transform-origin: center;
}